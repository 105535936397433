import ws from '../utils/ws';

/* Actions */

export const softwarePackagePaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: SOFTWARE_PACKAGE_PAGINATED_LIST,
    payload: ws.get(`/software/packages/page/${pageNumber}/rows/${pageSize}`)
  });

export const softwarePackageValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_SOFTWARE_PACKAGE_VALUE_UPDATE,
    payload: values
  });

export const findFieldProductionCellSoftwareVersionInstallationCounts = () => dispatch =>
  dispatch({
    type: FIND_FIELD_PRODUCTION_CELL_SOFTWARE_VERSION_INSTALLATION_COUNTS,
    payload: ws.get(
      `/software/packages/reports/installation_counts/?cellStatus=FIELD_PRODUCTION&packages=xonar-cell-software,xonar-cell-software-docker`
    )
  });

export const findDashboardSoftwarePackages = () => dispatch =>
  dispatch({
    type: FIND_DASHBOARD_SOFTWARE_PACKAGES,
    payload: ws.get(`/software/packages/`)
  });

export const updateSoftwarePackageStatuses = (
  softwarePackageIds,
  status
) => dispatch =>
  dispatch({
    type: UPDATE_SOFTWARE_PACKAGE_STATUSES,
    payload: ws.post(`/software/packages/update_statuses`, {
      softwarePackageIds,
      status
    })
  });

export const softwarePackageValueReset = () => dispatch =>
  dispatch({
    type: CREATE_SOFTWARE_PACKAGE_VALUE_RESET
  });

export const findSoftwarePackage = id => dispatch =>
  dispatch({
    type: FIND_SOFTWARE_PACKAGE,
    payload: ws.get(`/software/packages/${id}`)
  });

export const findSoftwarePackageInstalledCells = id => dispatch =>
  dispatch({
    type: FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS,
    payload: ws.get(`/software/packages/${id}/installed_cells/`)
  });

export const setSoftwarePackageStatus = (id, status) => dispatch =>
  dispatch({
    type: SET_SOFTWARE_PACKAGE_STATUS,
    payload: ws.post(`/software/packages/${id}/status`, { status })
  });

export const selectSoftwarePackageListPackge = softwarePackage => dispatch =>
  dispatch({
    type: SELECT_SOFTWARE_PACKAGE_LIST_PACKAGE,
    payload: softwarePackage.id
  });

export const saveSoftwarePackage = softwarePackage => dispatch =>
  dispatch({
    type: SAVE_SOFTWARE_PACKAGE,
    payload: ws.post('/software/packages/', softwarePackage)
  });

export const editSoftwarePackage = id => dispatch =>
  dispatch({
    type: EDIT_SOFTWARE_PACKAGE,
    payload: ws.get(`/software/packages/${id}`)
  });

/* Constants */

export const SOFTWARE_PACKAGE_PAGINATED_LIST =
  'SOFTWARE_PACKAGE_PAGINATED_LIST';
export const SOFTWARE_PACKAGE_PAGINATED_LIST_PENDING =
  'SOFTWARE_PACKAGE_PAGINATED_LIST_PENDING';
export const SOFTWARE_PACKAGE_PAGINATED_LIST_FULFILLED =
  'SOFTWARE_PACKAGE_PAGINATED_LIST_FULFILLED';

export const CREATE_SOFTWARE_PACKAGE_VALUE_UPDATE =
  'CREATE_SOFTWARE_PACKAGE_VALUE_UPDATE';
export const CREATE_SOFTWARE_PACKAGE_VALUE_RESET =
  'CREATE_SOFTWARE_PACKAGE_VALUE_RESET';

export const FIND_SOFTWARE_PACKAGE = 'FIND_SOFTWARE_PACKAGE';
export const FIND_SOFTWARE_PACKAGE_PENDING = 'FIND_SOFTWARE_PACKAGE_PENDING';
export const FIND_SOFTWARE_PACKAGE_FULFILLED =
  'FIND_SOFTWARE_PACKAGE_FULFILLED';
export const FIND_DASHBOARD_SOFTWARE_PACKAGES =
  'FIND_DASHBOARD_SOFTWARE_PACKAGES';
export const FIND_DASHBOARD_SOFTWARE_PACKAGES_PENDING =
  'FIND_DASHBOARD_SOFTWARE_PACKAGES_PENDING';
export const FIND_DASHBOARD_SOFTWARE_PACKAGES_FULFILLED =
  'FIND_DASHBOARD_SOFTWARE_PACKAGES_FULFILLED';

export const FIND_FIELD_PRODUCTION_CELL_SOFTWARE_VERSION_INSTALLATION_COUNTS =
  'FIND_FIELD_PRODUCTION_CELL_SOFTWARE_VERSION_INSTALLATION_COUNTS';
export const FIND_FIELD_PRODUCTION_CELL_SOFTWARE_VERSION_INSTALLATION_COUNTS_PENDING =
  'FIND_FIELD_PRODUCTION_CELL_SOFTWARE_VERSION_INSTALLATION_COUNTS_PENDING';
export const FIND_FIELD_PRODUCTION_CELL_SOFTWARE_VERSION_INSTALLATION_COUNTS_FULFILLED =
  'FIND_FIELD_PRODUCTION_CELL_SOFTWARE_VERSION_INSTALLATION_COUNTS_FULFILLED';

export const FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS =
  'FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS';
export const FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS_PENDING =
  'FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS_PENDING';
export const FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS_FULFILLED =
  'FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS_FULFILLED';

export const SET_SOFTWARE_PACKAGE_STATUS = 'SET_SOFTWARE_PACKAGE_STATUS';
export const SET_SOFTWARE_PACKAGE_STATUS_PENDING =
  'SET_SOFTWARE_PACKAGE_STATUS_PENDING';
export const SET_SOFTWARE_PACKAGE_STATUS_FULFILLED =
  'SET_SOFTWARE_PACKAGE_STATUS_FULFILLED';

export const UPDATE_SOFTWARE_PACKAGE_STATUSES =
  'UPDATE_SOFTWARE_PACKAGE_STATUSES';
export const UPDATE_SOFTWARE_PACKAGE_STATUSES_PENDING =
  'UPDATE_SOFTWARE_PACKAGE_STATUSES_PENDING';
export const UPDATE_SOFTWARE_PACKAGE_STATUSES_FULFILLED =
  'UPDATE_SOFTWARE_PACKAGE_STATUSES_FULFILLED';

export const SAVE_SOFTWARE_PACKAGE = 'SAVE_SOFTWARE_PACKAGE';
export const SAVE_SOFTWARE_PACKAGE_PENDING = 'SAVE_SOFTWARE_PACKAGE_PENDING';
export const SAVE_SOFTWARE_PACKAGE_FULFILLED =
  'SAVE_SOFTWARE_PACKAGE_FULFILLED';

export const EDIT_SOFTWARE_PACKAGE = 'EDIT_SOFTWARE_PACKAGE';
export const EDIT_SOFTWARE_PACKAGE_PENDING = 'EDIT_SOFTWARE_PACKAGE_PENDING';
export const EDIT_SOFTWARE_PACKAGE_FULFILLED =
  'EDIT_SOFTWARE_PACKAGE_FULFILLED';

export const SELECT_SOFTWARE_PACKAGE_LIST_PACKAGE =
  'SELECT_SOFTWARE_PACKAGE_LIST_PACKAGE';
