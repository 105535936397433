import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  saved: false,
  savedCustomer: {},
  deleted: false,
  deletedCustomer: {},
  customer: {
    address: '',
    countryCode: '',
    customerName: '',
    emailAddress: '',
    marketingCustomer: null,
    modelConfiguration: null,
    multiModelDesignated: null,
    phoneNumber: '',
    shortCode: '',
    hubSpotId: '',
    threshold: null,
    authorizedPortalEmailDomains: []
  },
  validationMessages: {}
};

const customerCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CUSTOMER_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedCustomer: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_CUSTOMER_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deletedCustomer: action.payload.data.result
      };
    }

    case actionTypes.EDIT_CUSTOMER_FULFILLED: {
      return {
        ...state,
        customer: action.payload.data.result
      };
    }

    case actionTypes.CREATE_CUSTOMER_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.ADD_CUSTOMER_AUTHORIZED_DOMAIN: {
      const domains = state.customer.authorizedPortalEmailDomains
        ? state.customer.authorizedPortalEmailDomains.slice()
        : [];
      domains.push('');
      return {
        ...state,
        customer: {
          ...state.customer,
          authorizedPortalEmailDomains: domains
        }
      };
    }

    case actionTypes.REMOVE_CUSTOMER_AUTHORIZED_DOMAIN: {
      const domains = state.customer.authorizedPortalEmailDomains.slice();
      const index = action.payload;
      domains.splice(index, 1);
      return {
        ...state,
        customer: {
          ...state.customer,
          authorizedPortalEmailDomains: domains
        }
      };
    }

    case actionTypes.SET_CUSTOMER_AUTHORIZED_DOMAIN_VALUE: {
      const domains = state.customer.authorizedPortalEmailDomains.slice();
      const { value, index } = action.payload;
      domains[index] = value;
      return {
        ...state,
        customer: {
          ...state.customer,
          authorizedPortalEmailDomains: domains
        }
      };
    }

    case actionTypes.CREATE_CUSTOMER_VALUE_UPDATE: {
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload
        }
      };
    }

    default:
      return state;
  }
};

export default customerCreateReducer;
