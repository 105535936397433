import * as actionTypes from 'actions';

const emptyFilter = {
  validationStatus: null,
  restrictedEventCount: null,
  search: null,
  dataPointId: null,
  fileName: null,
  trainerId: null,
  eventTimeFrom: null,
  eventTimeTo: null,
  processingTimeFrom: null,
  processingTimeTo: null,
  subjectTemperatureFrom: null,
  subjectTemperatureTo: null,
  validatedTimeFrom: null,
  validatedTimeTo: null,
  validatedByName: null,
  objectPercent: [0, 0],
  objectLeftPercent: [0, 0],
  objectRightPercent: [0, 0],
  objectPresent: false,
  objectNotPresent: false,
  objectLeftSide: false,
  objectLeftChest: false,
  objectLeftFoot: false,
  objectRightSide: false,
  objectRightChest: false,
  objectRightFoot: false,
  objectBag: false,
  objectBagLeftShoulderHigh: false,
  objectBagLeftShoulderLow: false,
  objectBagRightShoulderHigh: false,
  objectBagRightShoulderLow: false,
  objectBagLeftHand: false,
  objectBagRightHand: false,
  objectBagBack: false,
  objectBagFannypack: false,
  objectHead: false,
  objectFront: false,
  objectBack: false,
  selectedEventCount: null
};

const initialState = {
  eventDataSetSaving: false,
  filterOpen: false,
  filterSaving: false,
  saved: false,
  savedEventDataSet: {},
  deleted: false,
  deleteMessage: null,
  deletedEventDataSet: {},
  currentFilterIndex: 0,
  eventDataSet: {
    reAssociateWalks: false,
    creationId: null,
    name: '',
    description: '',
    excludeInvalidWalks: true,
    filters: [
      {
        ...emptyFilter
      }
    ]
  },
  deleting: false,
  validationMessages: {},
  filtersEdited: false,
  reAssociationSensitiveFieldsEdited: false
};

const eventDataSetCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_EVENT_DATA_SET_PENDING: {
      return {
        ...state,
        eventDataSetSaving: true
      };
    }

    case actionTypes.SAVE_EVENT_DATA_SET_FULFILLED: {
      return {
        ...state,
        eventDataSetSaving: false,
        saved: action.payload.data.success,
        savedEventDataSet: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_EVENT_DATA_SET_PENDING: {
      return {
        ...state,
        deleted: initialState.deleted,
        deleting: true,
        deletedEventDataSet: initialState.deletedEventDataSet
      };
    }

    case actionTypes.DELETE_EVENT_DATA_SET_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deleteMessage: action.payload.data.message,
        deletedEventDataSet: action.payload.data.result,
        deleting: false
      };
    }

    case actionTypes.SET_EVENT_DATA_SET_CREATE_FILTER_INDEX: {
      return {
        ...state,
        currentFilterIndex: action.payload
      };
    }

    case actionTypes.SET_EVENT_DATA_SET_CREATE_FILTER_VALUE: {
      const filters = state.eventDataSet.filters.slice();

      const { field, value, filterIndex } = action.payload;

      filters[filterIndex][field] = value;

      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          filters: filters
        },
        filtersEdited: true
      };
    }

    case actionTypes.ADD_EVENT_DATA_SET_FILTER: {
      const filters = state.eventDataSet.filters.slice();
      filters.push({ ...emptyFilter });

      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          filters: filters
        },
        filtersEdited: true
      };
    }

    case actionTypes.CLEAR_EVENT_DATA_SET_FILTER: {
      const filters = state.eventDataSet.filters.slice();
      const filterIndex = action.payload;
      filters[filterIndex] = { ...emptyFilter };

      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          filters: filters
        },
        filtersEdited: true
      };
    }

    case actionTypes.REMOVE_EVENT_DATA_SET_FILTER: {
      const filters = state.eventDataSet.filters.slice();
      filters.splice(action.payload, 1);

      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          filters: filters
        },
        filtersEdited: true
      };
    }

    case actionTypes.SAVE_EVENT_DATA_SET_FILTER_PENDING: {
      return {
        ...state,
        filterSaving: true,
        filtersEdited: true
      };
    }

    case actionTypes.SAVE_EVENT_DATA_SET_FILTER_FULFILLED: {
      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          reAssociateWalks: true,
          filters: action.payload.data.result.filters
        },
        filterSaving: false,
        filterOpen: false
      };
    }

    case actionTypes.UPLOAD_EVENT_DATA_SET_FILTER_WALKS_FILE_FULFILLED: {
      const filters = state.eventDataSet.filters.slice();

      const { filterIndex, fileName, walkIds } = action.payload.data.result;

      filters[filterIndex] = {
        ...filters[filterIndex],
        walkUploadFileName: fileName,
        walkUploadFileEventIds: walkIds
      };

      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          filters: filters
        },
        filtersEdited: true
      };
    }

    case actionTypes.EDIT_EVENT_DATA_SET_FULFILLED: {
      const eventDataSet = {
        ...action.payload.data.result,
        identifiersCreated: true
      };

      return {
        ...state,
        eventDataSet
      };
    }

    case actionTypes.CREATE_EVENT_DATA_SET_VALUE_RESET: {
      return {
        ...initialState,
        eventDataSet: {
          ...initialState.eventDataSet,
          filters: [{ ...emptyFilter }]
        }
      };
    }

    case actionTypes.CREATE_EVENT_DATA_SET_VALUE_UPDATE: {
      // Todo check for re-association sensitive fields
      const reAssociationSensitiveFields = [
        'excludeInvalidWalks',
        'multiModelDesignated',
        'reAssociateWalks'
      ]; // Todo move to constant

      const reAssociationSensitiveFieldsEdited =
        state.reAssociationSensitiveFieldsEdited ||
        reAssociationSensitiveFields.indexOf(Object.keys(action.payload)[0]) >
          -1;

      const reAssociateWalks =
        state.eventDataSet.reAssociateWalks ||
        reAssociationSensitiveFieldsEdited;

      return {
        ...state,
        reAssociationSensitiveFieldsEdited: reAssociationSensitiveFieldsEdited,
        eventDataSet: {
          ...state.eventDataSet,
          ...action.payload,
          reAssociateWalks: reAssociateWalks
        }
      };
    }

    case actionTypes.CLEAR_EVENT_DATA_SET_DELETE_MESSAGE: {
      return {
        ...state,
        deleteMessage: initialState.deleteMessage
      };
    }

    case actionTypes.SET_EVENT_DATA_SET_FILTER_OPEN: {
      return {
        ...state,
        filterOpen: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default eventDataSetCreateReducer;
