import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  savedSoftwarePackage: {},
  softwarePackage: {
    releaseNotesUrl: '',
    jiraReleaseUrl: ''
  },
  validationMessages: {},
  loading: false
};

const softwarePackageCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_SOFTWARE_PACKAGE_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedSoftwarePackage: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.EDIT_SOFTWARE_PACKAGE_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.EDIT_SOFTWARE_PACKAGE_FULFILLED: {
      return {
        ...state,
        softwarePackage: {
          ...action.payload.data.result
        },
        loading: false
      };
    }

    case actionTypes.CREATE_SOFTWARE_PACKAGE_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_SOFTWARE_PACKAGE_VALUE_UPDATE: {
      return {
        ...state,
        softwarePackage: {
          ...state.softwarePackage,
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default softwarePackageCreateReducer;
