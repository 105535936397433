import * as actionTypes from 'actions';

const initialState = {
  fieldProductionInstallationCounts: [],
  fieldProductionInstallationCountsLoading: false,
  softwarePackages: [],
  softwarePackagesLoading: false
};

const softwareDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_FIELD_PRODUCTION_CELL_SOFTWARE_VERSION_INSTALLATION_COUNTS_PENDING: {
      return {
        ...state,
        fieldProductionInstallationCounts:
          initialState.fieldProductionInstallationCounts,
        fieldProductionInstallationCountsLoading: true
      };
    }

    case actionTypes.FIND_FIELD_PRODUCTION_CELL_SOFTWARE_VERSION_INSTALLATION_COUNTS_FULFILLED: {
      return {
        ...state,
        fieldProductionInstallationCounts: action.payload.data.result,
        fieldProductionInstallationCountsLoading: false
      };
    }

    case actionTypes.FIND_DASHBOARD_SOFTWARE_PACKAGES_PENDING: {
      return {
        ...state,
        softwarePackages: initialState.softwarePackages,
        softwarePackagesLoading: true
      };
    }

    case actionTypes.FIND_DASHBOARD_SOFTWARE_PACKAGES_FULFILLED: {
      return {
        ...state,
        softwarePackages: action.payload.data.result,
        softwarePackagesLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default softwareDashboardReducer;
